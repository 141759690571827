import React, { useRef } from "react";
import { about } from "../../assets";
import { keypoints } from "../../source";
import { FaCheck } from "react-icons/fa";
import Achievement from "../../components/Achievement";
import "./About.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20%% bottom",
            end: "bottom top",
          },
        })
        .fromTo(".about__image", { x: -50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          [
            ".title",
            ".sub__heading",
            ".section__header .description",
            ".keypoint",
          ],
          { opacity: 0 },
          { opacity: 1, stagger: 0.5 }
        );
    },
    { scope: container }
  );
  return (
    <section id="about" dir="rtl" ref={container}>
      <div className="container">
        <div className="grid">
          <div className="about__image">
            <img src={about} alt="" />
          </div>
          <div className="section__header">
          <h3 className="title primary" >BarCargo</h3>
<h2 className="sub__title">יוצרים פתרונות. מובילים לעתיד.</h2>
<p className="description" dir="rtl">חברת בר קרגו נוסדה בשנת 2020, ע"י ריקי בר.
ריקי, בעלת ניסיון של 40 שנה בתחום שרשרת האספקה, המנווטת את הצוות ביד רמה למצויינות מול הלקוחות. במהלך השנים ריקי הובילה פרויקטים גדולים ומורכבים ואף שירתה לקוחות מהשורה הראשונה בייבוא ובייצוא מול ישראל.
בחברת בר קרגו הלקוח הוא מעל הכל, ולכן אנו דואגים שהמשלוחים יתנהלו במקצועיות ויצירתיות, תוך הבנת צורכי הלקוח  ותפירת פתרונות בזמן אמת.
בחברתנו אנו עובדים מול סוכנים בכל העולם, כולל סוכני נישה, שמטפלים במשלוחים חריגים / פרויקטים על מנת לתת ללקוח מגוון אפשרויות.
בין לקוחותיונו אפשר למצוא את עסקי הברזל, השקיה, פלסטיקה, חקלאות ועוד…
אנו מתנהלים  באופן טכנולוגי ואישי מול ספקי השירות: חברות הספנות, חברות התעופה, מובילים יבשתיים ועוד.
             הצוות מציע זמינות אמיתית של 24/7 תוך מתן מענה מיידי ללקוח 
 אנו מציעים חוויית שירות בוטיק עם מחירים הוגנים בשוק
</p>
<h3 className="sub__title">על מה אנחנו עומדים</h3>

            {keypoints.map((keypoint, index) => (
              <div className="flex keypoint" key={index}>
                <div className="flex__center primary icon__container">
                  <FaCheck />
                </div>
                <div>
                  <h4>{keypoint.title}</h4>
                  <p className="muted">{keypoint.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="achieve">
  <div className="overlay grid">
    <div className="section__header">
      <h3 className="title primary">ההישגים שלנו</h3>
      <h2 className="sub__heading">הופכים חזון למציאות</h2>
    </div>
    <div>
      <Achievement />
    </div>
  </div>
</div>

      </div>
    </section>
  );
};

export default About;
