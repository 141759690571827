import Header from "./Sections/Header";
import Navbar from "./Sections/Navbar"
import Services from "./Sections/Services";
import About from "./Sections/About"
import Testimonial from "./Sections/Testimonial";
import Contact from "./Sections/Contact"
import WhatsAppWidget from "./components/WhatsAppWidget";


import "swiper/css"
function App() {
  return (
    <>
     <Navbar/>
     <Header/>
     <Services/>
     <About/>
     <Testimonial/>
     <Contact />
     <WhatsAppWidget/>
    </>
  );
}

export default App;
