import React from 'react';
import './Logo.css';
import logo from '../../assets/barcargo_logo.png';

const Logo = () => {
  return (
    <div className='flex__center logo__container'>
      <a href="https://barcargo.co.il" className='flex__center icon__container'>
        <img src={logo} alt="BarCargo Logo" />
      </a>
      <h2 className="title">Bar <span className="primary">Cargo</span></h2>
    </div>
  );
};

export default Logo;
