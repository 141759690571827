import React, { useRef } from "react";
import "./Services.css";
import { Link } from "react-scroll";
import { sketch } from "../../assets";
import { services } from "../../source";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Services = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20%% bottom",
            end: "bottom top",
          },
        })
        .fromTo(
          [".section__header .sub__heading", ".section__header .description"],
          { x: -50, opacity: 0 },
          { x: 0, opacity: 1, stagger: 0.5 }
        )
        .fromTo(
          ".section__header .btn",
          { y: 50, opacity: 0 },
          { y: 0, opacity: 1 }
        )
        .fromTo(".image__contain", { x: 50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          ".service",
          { y: 50, opacity: 0 },
          { y: 0, opacity: 1, stagger: 0.5 }
        );
    },
    { scope: container }
  );
  return (
    <section id="services" ref={container}>
      <div className="overlay">
        <div className="container">
          <div className="grid">
            <div className="section__header">
              <h2 className="sub__heading" dir="rtl">
             להפוך את ההובלות הטובות ביותר למציאות
              </h2>
              <p className="description" dir="rtl">
              BarCargo היא חברה מובילה בתחום הובלת מחולות ברחבי העולם, המציעה שירותי שילוח מתקדמים למדינות רבות. עם צי אוניות מודרני ותשתיות נמל מתקדמות, החברה מתמחה בהעברת סחורות באופן מהיר, בטוח ויעיל. ל-BarCargo יש פריסה עולמית, והיא מספקת שירותים ללקוחות מתחומים שונים, כולל תעשיות כבדות, קמעונאות, וטכנולוגיה. כל משלוח מתבצע תחת פיקוח קפדני, עם מערכות ניהול מתקדמות המאפשרות מעקב בזמן אמת. החברה מחויבת לפתרונות ידידותיים לסביבה ולהפחתת טביעת הפחמן בכל תהליך השילוח.
              </p>
              <Link to="contact" smooth={true} className="btn primary">
               צור 
              </Link>
            </div>
            <div className="image__contain">
              <img src={sketch} alt="" />
            </div>
          </div>
          {/* end grid */}
          <div className="grid services" dir="rtl">
            {services.map((service, index) => (
              <div className="service" key={index}>
                <div className="flex top">
                  <div className="flex__center icon__container">
                    {service.icon}
                  </div>
                  <h4 className="title">{service.title}</h4>
                </div>
                <div className="middle">
                  <p className="description">{service.description}</p>
                </div>
                <div className="flex bottom">
                  <Link to="contact" smooth={true} className="btn">
                ראה עוד
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
