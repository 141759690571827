// src/components/WhatsAppWidget.js
import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import barcargo_logo from "../assets/barcargo_logo.jpg"

const WhatsAppWidget = () => {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+972507350087" // הכנס את מספר הטלפון שלך
        accountName="BarCargo" // הכנס את שם החשבון שלך
        avatar={barcargo_logo} // הכנס כתובת אווטאר, אופציונלי
        statusMessage="Online"
        chatMessage="שלום איך נוכל לעזור לך ?" // הודעה ראשונית
        allowClickAway // מאפשר סגירת הצ'אט בלחיצה מחוץ לחלון
      />
    </div>
  );
};

export default WhatsAppWidget;
