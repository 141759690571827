import {
    FaTools,
    FaCheckCircle,
    FaClock,
    FaDraftingCompass,
    FaUserFriends,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaYoutube,
    FaHome,
    FaBuilding,
  } from "react-icons/fa";
  import {
    user1,
    user2,
    user3,
    user4,
  } from "./assets";
  import { LiaShipSolid } from "react-icons/lia";
  import { RiGlobalLine } from "react-icons/ri";
  import { PiShippingContainerDuotone } from "react-icons/pi";
  
  export const navigations = [
    {
      label: "ראשי",
      to: "header",
    },
    {
      label: "שירותים",
      to: "services",
    },
    {
      label: "עלינו",
      to: "about",
    },
    {
      label: "חוות דעת",
      to: "testimonials",
    },
    {
      label: "צור קשר",
      to: "contact",
    },
  ];
  
  export const keypoints = [
    {
      title: "מומחיות בשילוח",
      description: "כל פרט חשוב. אנו מבטיחים שירותי שילוח ברמה גבוהה בכל שלב של התהליך.",
    },
    {
      title: "עמידה בזמנים ותקציב",
      description: "יעילות היא המפתח. אנו מבטיחים שהמטענים יגיעו ליעדם בזמן וללא עלויות בלתי צפויות.",
    },
    {
      title: "פתרונות חדשניים",
      description: "אנו משתמשים בטכנולוגיות המתקדמות ביותר ובשיטות ברות קיימא כדי להוביל את העתיד.",
    },
    {
      title: "גישה ממוקדת בלקוח",
      description: "אנו מקשיבים לצרכים שלכם ומתאימים את שירותינו על מנת לעלות על ציפיותיכם.",
    },
  ];
  
  
  export const whyChooseUs = [
    {
      title: "איכות",
      icon: <FaCheckCircle />,
      description: "דגש על איכות ההובלה",
    },
    {
      title: "זמן משלוח",
      icon: <FaClock />,
      description: "הובלות לפי לוחות זמנים",
    },
    {
      title: "חדשנות",
      icon: <FaDraftingCompass />,
      description: "ציוד חדשני ",
    },
    {
      title: "שירות ממוקד",
      icon: <FaUserFriends />,
      description: "מתן עדיפות לשביעות רצון הלקוח תמיד",
    },
  ];
  
  export const services = [
    {
      title:  "שירותי הובלת מכולות גלובליים",
      icon:<RiGlobalLine />,
      description: `הובלת סחורות ברחבי העולם עם שירותי שילוח מכולות יעילים ואמינים.`,
    },
    {
      title:  "פתרונות לוגיסטיקה וניהול שרשרת אספקה",
      icon: <LiaShipSolid />,
      description: `ניהול לוגיסטי מקצה לקצה ואופטימיזציה של שרשרת האספקה לעסקים.`,
    },
    {
      title: "שירותי מטען מותאמים אישית",
      icon: <PiShippingContainerDuotone />,
      description: `פתרונות מותאמים אישית לשילוח מכולות, המבטיחים הובלה בטוחה, יעילה, ומהירה בהתאם לצרכי הלקוח.`,
    },
    
  ];
  
  export const testimonials = [
    {
      name: "דנה לוי",
      image: user1,
      review: `הצוות ב-BarCargo עלה על כל הציפיות שלנו. תשומת הלב שלהם לפרטים והמקצועיות הפכו את תהליך השילוח שלנו לחוויה חלקה ומהנה.`,
    },
    {
      name: "יוסי כהן",
      image: user2,
      review: `מההתחלה ועד הסוף, הפרויקט נוהל בכישרון וביעילות מדהימה. אני ממליץ בחום על BarCargo לכל צורך בשילוח.`,
    },
    {
      name: "נעמה ישראלי",
      image: user3,
      review: `העבודה עם BarCargo הייתה חוויה נהדרת. הצוות היה מקצועי, יצירתי ומחויב לספק בדיוק את מה שדמיינו.`,
    },
    {
      name: "אבי ברקוביץ'",
      image: user4,
      review: `שירות יוצא דופן ואיכות ללא פשרות! BarCargo הפכה את תהליך השילוח שלנו ליעיל ומדויק. הם מקצוענים אמיתיים.`,
    },
  ];
  
  
  export const footer = [
    {
      title: "Company",
      routes: [
        {
          name: "About Us",
          href: "#about",
        },
        {
          name: "Careers",
          href: "#",
        },
        {
          name: "Contact Us",
          href: "#contact",
        },
      ],
    },
    {
      title: "Services",
      routes: [
        {
          name: "Residential Construction",
          href: "#",
        },
        {
          name: "Commercial Projects",
          href: "#",
        },
        {
          name: "Renovation & Remodeling",
          href: "#",
        },
        {
          name: "Project Management",
          href: "#",
        },
      ],
    },
    {
      title: "Resources",
      routes: [
        {
          name: "Case Studies",
          href: "#",
        },
        {
          name: "FAQs",
          href: "#",
        },
        {
          name: "Privacy Policy",
          href: "#",
        },
      ],
    },
  ];
  
  export const socialHandles = [
    {
      name: "Facebook",
      icon: <FaFacebook />,
      link: "https://www.facebook.com",
    },
    {
      name: "Instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com",
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com",
    },
    {
      name: "Youtube",
      icon: <FaYoutube />,
      link: "",
    },
  ];