import React, { useRef } from "react";
import "./Contact.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20%% bottom",
            end: "bottom top",
          },
        })
        .fromTo(".form", { x: 50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          ".contact__infos",
          { y: 100, opacity: 0 },
          { y: 0, opacity: 1 }
        );
    },
    { scope: container }
  );

  return (
    <section id="contact" dir="rtl" ref={container}>
      <div className="container">
        <form className="form">
          <div className="form__top">
            <h3 className="sub__heading">צור קשר</h3>
            <p className="muted">
              יש לכם פרויקט בראש או זקוקים לייעוץ מומחה? אנחנו כאן כדי לעזור!
              פנו אל הצוות שלנו לייעוץ אישי, שאלות, או כדי להתחיל בפרויקט הבא שלכם כבר היום.
            </p>
          </div>
          <div className="form__middle">
            <input
              type="text"
              placeholder="שם מלא"
              name="fullName"
              className="control"
            />
            <input
              type="email"
              placeholder="כתובת אימייל"
              name="email"
              className="control"
            />
            <input
              type="tel"
              placeholder="מספר טלפון"
              name="phoneNumber"
              className="control"
            />
            <textarea
              name="message"
              placeholder="הודעתך"
              className="control"
            ></textarea>
          </div>
          <div className="form__bottom">
            <button className="btn primary">שלח הודעה</button>
          </div>
        </form>
        <div className="contact__infos">
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <MdOutlineAlternateEmail />
            </div>
            <div className="details">
              <h4>שלח לנו אימייל</h4>
              <p className="muted">תמיכה מהירה ואמינה</p>
              <p>
                <a href="mailto:info@barcargo.co.il">barcargo24@gmail.com</a>
              </p>
            </div>
          </div>

          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <FaLocationCrosshairs />
            </div>
            <div className="details">
              <h4>הכתובת שלנו</h4>
              <p className="muted">בואו לבקר אותנו במשרד</p>
              <p>שבטי ישראל 33, ראשון לציון, ישראל</p>
            </div>
          </div>

          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <FiPhoneCall />
            </div>
            <div className="details">
              <h4>מספר טלפון</h4>
              <p className="muted">התקשרו אלינו</p>
              <p>0507350087 </p>
            </div>
          </div>
        </div>
      </div>

      {/* שורת זכויות יוצרים */}
      <div className="footer">
      <p className="copyright">כל הזכויות שמורות ל- BarCargo © 2024 | אתר נבנה על ידי <a href="https://www.more-ways.co.il" target="_blank" rel="noopener noreferrer">www.more-ways.co.il</a></p>

      </div>
    </section>
  );
};

export default Contact;
